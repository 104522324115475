.button_pink {
  background-color: #ef60a3;
  color: white;
  border-radius: 50px;
  font-weight: 900;
}

.button_grey {
  background-color: #dddddd;
  color: white;
  border-radius: 50px;
  font-weight: 900;
}

.button_grey_1 {
  background-color: #dddddd;
  color: white;
  border-radius: 50px;
  font-weight: 900;
}

.button_pink_outline {
  background-color: #ffffff;
  color: #ef60a3;
  border-radius: 50px;
  font-weight: 900;
  border: 1px solid #ef60a3;
}

.previous-btn-pay {
  width: 50px;
  height: 50px;
  background-color: var(--white);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  left: -10px;
  margin-top: 100px;
  z-index: 3;
  cursor: pointer;
  @media (max-width: 996px) {
    left: 0px;
  }
  @media (max-width: 476px) {
    display: none;
  }
}

.next-btn-pay {
  width: 50px;
  height: 50px;
  background-color: var(--white);
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: -50px;
  top: 0;
  margin-top: 100px;
  z-index: 3;
  cursor: pointer;
  @media (max-width: 996px) {
    right: -30px;
  }
  @media (max-width: 576px) {
    display: none;
  }
}

.progress_start {
  height: 142px;
  width: 7px;
  background: #dddddd;
  border-radius: 42px;

  @media (max-width: 1200px) {
    height: 250px;
  }
}

.circle_start {
  width: 35px;
  height: 35px;
  background: #dddddd;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 14px;
  margin-top: 16px;
}

.header_img_video {
  border-radius: 16px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  height: 153px;
  width: 100%;
  max-width: 272px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.text_pink {
}

.box_program {
  &:hover {
    border: 2px solid #ef60a3;
    cursor: pointer;
    .text_pink {
      color: #ef60a3;
    }
    .button_grey_1 {
      background-color: #ef60a3;
    }
  }
}

.circle_step {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
