.line3 {
  width: 1440px;
  height: 700px;
  background-image: url("../../../../../src/assets/img/home/3line.png");
  background-size: cover;
}

.box_screen1 {
  background: linear-gradient(to bottom, #fff, #ffbbd6);
  width: 100%;
  height: 700px;
  position: relative;
}

.box_screen2 {
  background: linear-gradient(to bottom, #ef9cc3, #ef60a3);
  width: 100%;
  height: 700px;
  position: relative;
}

.box_screen3 {
  background: linear-gradient(to bottom, #ef60a3, #960051);
  width: 100%;
  height: 700px;
  position: relative;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.flex_center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.box_animation {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.carousel-indicators {
  position: absolute;
  top: 77%;
  width: 20%;
  z-index: 20;
  left: 50%;
  justify-content: left;
  margin-left: 0;
}

.carousel-indicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #f7abce;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-left: 10px;
}

.carousel-indicator:hover,
.carousel-indicator:focus {
  background-color: #960051;
}

.carousel-indicator:checked {
  background-color: #960051;
}

.carousel-indicator:checked + label {
  background-color: #960051;
}
.carousel-indicators input[type="radio"] {
  width: 13px;
  cursor: pointer;
  display: none;
}

.home1-detail {
  position: relative;
  z-index: 11;
  width: 100%;
  height: 100%;
}
.home1-detail1 {
  position: relative;
  z-index: 11;
  width: 100%;
  height: 100%;
}
.home1-detail2 {
  position: relative;
  z-index: 11;
  width: 100%;
  height: 100%;
}
.slide-img1,
.slide-img2,
.slide-img3 {
  position: relative;
  height: auto;
  width: 100%;
  z-index: 2;
}
.slide-text1,
.slide-text2,
.slide-text3 {
  position: absolute;
  z-index: 10;
}
.slide-text1,
.slide-text2 {
  top: 54%;
}
.slide-text3 {
  top: 62%;
}
.slide-text2,
.slide-text3 {
  color: white;
}
.button-home1 {
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-left: 0px;
  position: absolute;
  font-family: SemiBoldPynk;
  z-index: 10;
  background-color: white;
  color: #ef60a3;
  border: solid 1px #ef60a3;
  border-radius: 50px;
  height: 9%;
  top: 70%;
  width: 26%;
}

.texthome1 {
  display: flex;
  align-items: center;
  position: relative;
}
.text-anime {
  width: 100%;
  position: relative;
  z-index: 10;
  height: 525px;
  overflow: hidden;
}
.text-slide {
  width: 100%;
  height: 100%;
  opacity: 1;
}

.text-anime0to1 {
  animation: text-anime0to1 0.6s forwards;
}
.text-anime1to0 {
  animation: text-anime1to0 0.6s forwards;
}
.text-anime1to2 {
  animation: text-anime1to2 0.6s forwards;
}
.text-anime2to1 {
  animation: text-anime2to1 0.6s forwards;
}
.text-anime2to0 {
  animation: text-anime2to0 0.6s linear forwards;
}
.text-anime0to2 {
  animation: text-anime0to2 0.6s linear forwards;
}
@keyframes text-anime0to1 {
  100% {
    transform: translateY(-100%);
    opacity: 1;
  }
}
@keyframes text-anime1to0 {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes text-anime1to2 {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(-200%);
    opacity: 1;
  }
}
@keyframes text-anime2to1 {
  0% {
    transform: translateY(-200%);
    opacity: 0;
  }
  100% {
    transform: translateY(-100%);
    opacity: 1;
  }
}
@keyframes text-anime2to0 {
  0% {
    transform: translateY(200%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes text-anime0to2 {
  0% {
    transform: translateY(0%);
    opacity: 0;
  }
  100% {
    transform: translateY(-200%);
    opacity: 1;
  }
}

.home1-detail {
  height: 100%;
  width: 100%;
}
.slide-text1,
.slide-text2,
.slide-text3 {
  top: 55%;
  font-size: 24px;
  width: 70%;
  left: 0%;
}
.slide-text3 {
  top: 63%;
}

.bebe-slide {
  background-image: url("../../../../assets/img/home/bebeSlide.png");
  background-size: cover;
  min-width: 1390px;
  height: 2010px;
  top: 0;
  left: -45%;
  opacity: 1;
  position: absolute;
  z-index: 10;
}

.bebe-slide0to1 {
  animation: bebe-slide0to1 0.6s forwards;
}
.bebe-slide1to0 {
  animation: bebe-slide1to0 0.6s forwards;
}
.bebe-slide1to2 {
  animation: bebe-slide1to2 0.6s forwards;
}
.bebe-slide2to1 {
  animation: bebe-slide2to1 0.6s forwards;
}
.bebe-slide2to0 {
  animation: bebe-slide2to0 0.6s linear forwards;
}
.bebe-slide0to2 {
  animation: bebe-slide0to2 0.6s linear forwards;
}
@keyframes bebe-slide0to1 {
  100% {
    top: -45%;
    left: -47%;
    transform: rotate(-90deg);
  }
}
@keyframes bebe-slide1to0 {
  0% {
    top: -45%;
    left: -47%;
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@keyframes bebe-slide1to2 {
  0% {
    top: -45%;
    left: -47%;
    transform: rotate(-90deg);
  }
  100% {
    top: 0;
    left: -89%;
    transform: rotate(-180deg);
  }
}
@keyframes bebe-slide2to1 {
  0% {
    top: 0;
    left: -89%;
    transform: rotate(-180deg);
  }
  100% {
    top: -45%;
    left: -47%;
    transform: rotate(-90deg);
  }
}
@keyframes bebe-slide2to0 {
  0% {
    top: 0;
    left: -89%;
    transform: rotate(-180deg);
  }
  100% {
    transform: rotate(-360deg);
  }
}
@keyframes bebe-slide0to2 {
  25% {
    transform: rotate(-45deg) scale(0.7);
    opacity: 0;
  }
  75% {
    transform: scale(0);
    opacity: 0;
    transform: rotate(-135deg);
  }
  100% {
    top: 0;
    left: -89%;
    opacity: 1;
    transform: rotate(-180deg);
  }
}

.bubbles-bottom {
  position: absolute;
  height: auto;
  width: 100%;
  z-index: 1;
  max-width: 257px;
  top: 55%;
  left: 8%;
}

.bubbles-top {
  position: absolute;
  height: auto;
  width: 100%;
  z-index: 1;
  max-width: 315px;
  top: 14%;
  left: 40%;
}

.rotate1to2 {
  animation: rotate1to2 0.6s linear forwards;
}

.rotate2to0 {
  animation: rotate2to0 0.6s linear forwards;
}

.rotate0to1 {
  animation: rotate0to1 0.6s linear forwards;
}

.rotate0to2 {
  animation: rotate0to2 0.6s linear forwards;
}

.rotate1to0 {
  animation: rotate1to0 0.6s linear forwards;
}

.rotate2to1 {
  animation: rotate2to1 0.6s linear forwards;
}

.rotate1to2-1 {
  animation: rotate1to2-1 0.6s linear forwards;
}

.rotate2to0-1 {
  animation: rotate2to0-1 0.6s linear forwards;
}

.rotate0to1-1 {
  animation: rotate0to1-1 0.6s linear forwards;
}

.rotate0to2-1 {
  animation: rotate0to2-1 0.6s linear forwards;
}

.rotate1to0-1 {
  animation: rotate1to0-1 0.6s linear forwards;
}

.rotate2to1-1 {
  animation: rotate2to1-1 0.6s linear forwards;
}

@keyframes rotate0to1 {
  100% {
    top: 57%;
    left: 36%;
    transform: rotate(-150deg);
  }
}
@keyframes rotate1to0 {
  0% {
    top: 57%;
    left: 36%;
    transform: rotate(-150deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate1to2 {
  0% {
    top: 57%;
    left: 36%;
    transform: rotate(-150deg);
  }
  100% {
    top: 61%;
    left: 50%;
    transform: rotate(-220deg);
  }
}
@keyframes rotate2to1 {
  0% {
    top: 61%;
    left: 50%;
    transform: rotate(-220deg);
  }

  100% {
    top: 57%;
    left: 36%;
    transform: rotate(-150deg);
  }
}

@keyframes rotate2to0 {
  0% {
    top: 61%;
    left: 50%;
    transform: rotate(-220deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotate0to2 {
  50% {
    transform: rotate(-90deg);
    opacity: 0;
  }
  75% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    top: 61%;
    left: 50%;
    transform: rotate(-220deg);
  }
}

@keyframes rotate0to1-1 {
  100% {
    top: 11%;
    left: 40%;
    transform: rotate(-45deg);
  }
}
@keyframes rotate1to0-1 {
  0% {
    top: 11%;
    left: 40%;
    transform: rotate(-45deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@keyframes rotate1to2-1 {
  0% {
    top: 11%;
    left: 40%;
    transform: rotate(-45deg);
  }

  100% {
    top: 8%;
    left: 12%;
    transform: rotate(-120deg);
  }
}
@keyframes rotate2to1-1 {
  0% {
    top: 8%;
    left: 12%;
    transform: rotate(-120deg);
  }

  100% {
    top: 11%;
    left: 40%;
    transform: rotate(-45deg);
  }
}

@keyframes rotate2to0-1 {
  0% {
    top: 8%;
    left: 12%;
    transform: rotate(-120deg);
  }

  100% {
    transform: rotate(-360deg);
  }
}

@keyframes rotate0to2-1 {
  50% {
    transform: rotate(-90deg);
    opacity: 0;
  }
  75% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    top: 8%;
    left: 12%;
    transform: rotate(-120deg);
  }
}

@media screen and (max-width: 1200px) {
  .slide-img1,
  .slide-img2,
  .slide-img3 {
    top: 5%;
  }
  .home1-detail {
    height: 95%;
  }
  .slide-text1,
  .slide-text2,
  .slide-text3 {
    top: 53%;
    font-size: 24px;
  }
  .slide-text3 {
    top: 57%;
  }
  .button-home1 {
    top: 75%;
    left: 0;
    height: 9%;
  }
  .bebe-slide {
    min-width: 1250px;
    height: 1810px;
    top: 5%;
    left: -63%;
  }

  @keyframes bebe-slide0to1 {
    100% {
      top: -32%;
      left: -48%;
      transform: rotate(-90deg);
    }
  }
  @keyframes bebe-slide1to0 {
    0% {
      top: -32%;
      left: -48%;
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes bebe-slide1to2 {
    0% {
      top: -32%;
      left: -48%;
      transform: rotate(-90deg);
    }
    100% {
      min-width: 1144px;
      height: 1610px;
      top: 5%;
      left: -85%;
      transform: rotate(-180deg);
    }
  }
  @keyframes bebe-slide2to1 {
    0% {
      min-width: 1144px;
      height: 1610px;
      top: 5%;
      left: -85%;
      transform: rotate(-180deg);
    }
    100% {
      top: -32%;
      left: -48%;
      transform: rotate(-90deg);
    }
  }
  @keyframes bebe-slide2to0 {
    0% {
      min-width: 1144px;
      height: 1610px;
      top: 5%;
      left: -85%;
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes bebe-slide0to2 {
    50% {
      transform: rotate(-90deg);
      opacity: 0;
    }
    75% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      min-width: 1144px;
      height: 1610px;
      top: 5%;
      left: -85%;
      transform: rotate(-180deg);
    }
  }
}

/* ipad pro*/
@media screen and (max-width: 1024px) {
  .bebe-slide {
    min-width: 1250px;
    height: 1800px;
    top: 8%;
    left: -68%;
  }

  @keyframes bebe-slide0to1 {
    100% {
      top: -32%;
      left: -64%;
      transform: rotate(-90deg);
    }
  }
  @keyframes bebe-slide1to0 {
    0% {
      top: -32%;
      left: -64%;
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes bebe-slide1to2 {
    0% {
      top: -32%;
      left: -64%;
      transform: rotate(-90deg);
    }
    100% {
      min-width: 1100px;
      height: 1560px;
      top: 8%;
      left: -105%;
      transform: rotate(-180deg);
    }
  }
  @keyframes bebe-slide2to1 {
    0% {
      min-width: 1100px;
      height: 1560px;
      top: 8%;
      left: -105%;
      transform: rotate(-180deg);
    }
    100% {
      top: -32%;
      left: -64%;
      transform: rotate(-90deg);
    }
  }
  @keyframes bebe-slide2to0 {
    0% {
      min-width: 1100px;
      height: 1560px;
      top: 8%;
      left: -105%;
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes bebe-slide0to2 {
    50% {
      transform: rotate(-90deg);
      opacity: 0;
    }
    75% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      min-width: 1100px;
      height: 1560px;
      top: 8%;
      left: -105%;
      transform: rotate(-180deg);
    }
  }

  .bubbles-top {
    left: 35%;
    top: 18%;
  }
}

/* ipad air */
@media screen and (max-width: 820px) {
  .home1-detail {
    height: 68%;
  }
  .button-home1 {
    width: 33%;
    top: 65%;
    left: 0%;
    height: 6%;
  }
  .bebe-slide {
    min-width: 1272px;
    height: 1774px;
    top: -3%;
    left: -104%;
    transform: scale(0.9);
  }

  @keyframes bebe-slide0to1 {
    100% {
      top: -30%;
      left: -102%;
      transform: rotate(-90deg);
    }
  }
  @keyframes bebe-slide1to0 {
    0% {
      top: -30%;
      left: -102%;
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes bebe-slide1to2 {
    0% {
      top: -30%;
      left: -102%;
      transform: rotate(-90deg);
    }
    100% {
      top: -20%;
      left: -153%;
      transform: rotate(-180deg) scale(0.7);
    }
  }
  @keyframes bebe-slide2to1 {
    0% {
      top: -11%;
      left: -153%;
      transform: rotate(-180deg) scale(0.7);
    }
    100% {
      top: -30%;
      left: -102%;
      transform: rotate(-90deg);
    }
  }
  @keyframes bebe-slide2to0 {
    0% {
      top: -11%;
      left: -153%;
      transform: rotate(-180deg) scale(0.7);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes bebe-slide0to2 {
    50% {
      transform: rotate(-90deg);
      opacity: 0;
    }
    75% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      top: -11%;
      left: -153%;
      transform: rotate(-180deg) scale(0.7);
    }
  }

  .bubbles-top {
    max-width: 250px;
    top: 19%;
    left: 34%;
  }
  .bubbles-bottom {
    max-width: 230px;
    top: 53%;
    left: 0%;
  }
  @keyframes rotate1to2-1 {
    0% {
      top: 11%;
      left: 40%;
      transform: rotate(-45deg);
    }

    100% {
      top: 15%;
      left: 8%;
      transform: rotate(-120deg);
    }
  }
  @keyframes rotate2to1-1 {
    0% {
      top: 15%;
      left: 12%;
      transform: rotate(-120deg);
    }

    100% {
      top: 11%;
      left: 40%;
      transform: rotate(-45deg);
    }
  }

  @keyframes rotate2to0-1 {
    0% {
      top: 15%;
      left: 12%;
      transform: rotate(-120deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes rotate0to2-1 {
    50% {
      transform: rotate(-90deg);
      opacity: 0;
    }
    75% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      top: 15%;
      left: 12%;
      transform: rotate(-120deg);
    }
  }
}

.bebe-slide-mobile {
  background-image: url("../../../../assets/img/home/bebeSlide_480.png");
  background-size: cover;
  width: 980px;
  height: 910px;
  top: 1%;
  left: -294px;
  opacity: 1;
  position: absolute;
  z-index: 10;
}

/* IP Pro max*/
@media screen and (max-width: 430px) {
  .line3 {
    background-image: url("../../../../assets/img/home/3line_430.png");
    width: 430px;
    height: 700px;
  }
  .box_screen1,
  .box_screen2,
  .box_screen3 {
    height: 700px;
  }
  .text-anime {
    width: 80%;
    height: 307px;
    left: 54px;
    top: -79%;
  }
  .home1-detail{
    height: 100%;
  }
  .slide-text1, .slide-text2, .slide-text3 {
    top: 53%;
    font-size: 16px;
    width: 95%;
  }
  .button-home1 {
    width: 60%;
    top: 71%;
    left: 0%;
    height: 16%;
  }
  .carousel-indicators {
    top: 86%;
    left: 48px;
  }
  .carousel-indicator {
    width: 16px;
    height: 16px;
  }
  .bubbles-top {
    max-width: 135px;
    top: 9%;
    left: 55%;
  }
  .bubbles-bottom {
    max-width: 135px;
    top: 33%;
    left: 20%;
  }

  .bebe-slide0to1-mobile {
    animation: bebe-slide0to1-mobile 0.6s forwards;
  }
  .bebe-slide1to0-mobile {
    animation: bebe-slide1to0-mobile 0.6s forwards;
  }
  .bebe-slide1to2-mobile {
    animation: bebe-slide1to2-mobile 0.6s forwards;
  }
  .bebe-slide2to1-mobile {
    animation: bebe-slide2to1-mobile 0.6s forwards;
  }
  .bebe-slide2to0-mobile {
    animation: bebe-slide2to0-mobile 0.6s linear forwards;
  }
  .bebe-slide0to2-mobile {
    animation: bebe-slide0to2-mobile 0.6s linear forwards;
  }
  @keyframes bebe-slide0to1-mobile {
    100% {
      top: 11%;
      left: -585px;
      transform: rotate(-90deg);
    }
  }
  @keyframes bebe-slide1to0-mobile {
    0% {
      top: 11%;
      left: -585px;
      transform: rotate(-90deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }
  @keyframes bebe-slide1to2-mobile {
    0% {
      top: 11%;
      left: -585px;
      transform: rotate(-90deg);
    }
    100% {
      top: 4%;
      left: -625px;
      transform: rotate(-180deg);
    }
  }
  @keyframes bebe-slide2to1-mobile {
    0% {
      top: 4%;
      left: -625px;
      transform: rotate(-180deg);
    }
    100% {
      top: 11%;
      left: -585px;
      transform: rotate(-90deg);
    }
  }
  @keyframes bebe-slide2to0-mobile {
    0% {
      top: 4%;
      left: -625px;
      transform: rotate(-180deg);
    }
    100% {
      transform: rotate(-360deg);
    }
  }
  @keyframes bebe-slide0to2-mobile {
    25% {
      transform: rotate(-45deg) scale(0.7);
      opacity: 0;
    }
    75% {
      transform: scale(0);
      opacity: 0;
      transform: rotate(-135deg);
    }
    100% {
      top: 4%;
      left: -625px;
      opacity: 1;
      transform: rotate(-180deg);
    }
  }
  @keyframes rotate0to1 {
    100% {
      top: 35%;
      left: 41%;
      transform: rotate(-150deg);
    }
  }
  @keyframes rotate1to0 {
    0% {
      top: 35%;
      left: 41%;
      transform: rotate(-150deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes rotate1to2 {
    0% {
      top: 35%;
      left: 41%;
      transform: rotate(-150deg);
    }
    100% {
      top: 42%;
      left: 53%;
      transform: rotate(-220deg);
    }
  }
  @keyframes rotate2to1 {
    0% {
      top: 42%;
      left: 53%;
      transform: rotate(-220deg);
    }

    100% {
      top: 35%;
      left: 41%;
      transform: rotate(-150deg);
    }
  }

  @keyframes rotate2to0 {
    0% {
      top: 42%;
      left: 53%;
      transform: rotate(-220deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes rotate0to2 {
    50% {
      transform: rotate(-90deg);
      opacity: 0;
    }
    75% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      top: 42%;
      left: 53%;
      transform: rotate(-220deg);
    }
  }

  @keyframes rotate0to1-1 {
    100% {
      top: 10%;
      left: 50%;
      transform: rotate(-45deg);
    }
  }
  @keyframes rotate1to0-1 {
    0% {
      top: 10%;
      left: 50%;
      transform: rotate(-45deg);
    }

    100% {
      transform: rotate(0deg);
    }
  }

  @keyframes rotate1to2-1 {
    0% {
      top: 10%;
      left: 50%;
      transform: rotate(-45deg);
    }

    100% {
      top: 10%;
      left: 15%;
      transform: rotate(-120deg);
    }
  }
  @keyframes rotate2to1-1 {
    0% {
      top: 10%;
      left: 15%;
      transform: rotate(-120deg);
    }

    100% {
      top: 10%;
      left: 50%;
      transform: rotate(-45deg);
    }
  }

  @keyframes rotate2to0-1 {
    0% {
      top: 10%;
      left: 15%;
      transform: rotate(-120deg);
    }

    100% {
      transform: rotate(-360deg);
    }
  }

  @keyframes rotate0to2-1 {
    50% {
      transform: rotate(-90deg);
      opacity: 0;
    }
    75% {
      opacity: 0;
      transform: scale(0);
    }
    100% {
      top: 10%;
      left: 15%;
      transform: rotate(-120deg);
    }
  }
}
