@media only screen and (max-width: 576px) {
    .order-summary {
        width: 364px !important;
    }

    .box-proceed-payment {
        width: 364px !important;
    }

    .justify-content-576 {
        justify-content: center !important;
        display: flex !important;
    }

    .image-product-order {
        width: 57px !important;
        height: 56px !important;
    }

    .body-order {
        padding-left: 10px !important;
        padding-right: 0 !important;
    }

    .box-button-login {
        padding: 0 !important;
    }



    .box-form-payment {
        margin-left: 0 !important;
        margin-right: 0 !important;
        background-color: rgba(232, 232, 232, 1) !important;
        border: 2px solid rgba(232, 232, 232, 1) !important;
        border-radius: 0 !important;
        padding: 39px 16px 85px 16px !important;
    }

    .box-promptPay {
        margin-left: 0 !important;
        margin-right: 0 !important;
        border-radius: 0 !important;
        padding: 39px 16px 85px 16px !important;
    }

    .box-line-placeholder {
        margin-left: -20px !important;
        width: 66px !important;
    }

    .box-line-pink {
        width: 33px !important;
    }

    .circle-p {
        font-size: 11px !important;
    }

    .mt-32-576 {
        margin-top: 32px;
        padding-right: 0px !important;
    }

    .customer-first-child-l {
        margin-left: 0 !important;
    }

    .customer-first-child-r {
        margin-right: 0 !important;
    }

    .customer-mt {
        margin-top: 16px !important;
    }

    .mb-32-576 {
        margin-bottom: 32px;
    }

    .text-qrcode-qrcode {
        font-size: 16px !important;
    }

    .baht-promptPay {
        font-size: 16px !important;
    }
}

@media only screen and (min-width: 577px) and (max-width: 768px) {
    .order-summary {
        width: 414px !important;
    }

    .box-proceed-payment {
        width: 300px !important;
    }

    .body-order {
        padding-left: 10px !important;
        padding-right: 0 !important;
    }

    .margin-left-768 {
        margin-left: 20px
    }

    .back-g {
        margin-left: 20px !important;
        margin-top: -5px !important;
    }

    .box-form-payment {
        padding: 39px 16px 85px 16px !important;
    }

    .box-promptPay {
        padding: 39px 16px 85px 16px !important;
        margin-top: 0px !important;
    }

    .text-qrcode-qrcode {
        font-size: 16px !important;
    }

    .baht-promptPay {
        font-size: 16px !important;
    }

    .justify-content-767 {
        display: flex !important;
        justify-content: center !important;
    }
}


.baht-promptPay {
    font-size: 32px;
}

.text-pink {
    color: var(--normal-pink) !important;
}

.between {
    justify-content: space-between;
    display: flex;
}

.mt-16 {
    margin-top: 16px !important;
}

.mt-32 {
    margin-top: 32px;
}

.box-order-summary {
    margin-top: 150px !important;
    background-color: var(--disabled-BG);
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    position: relative;
    box-sizing: border-box;
    /* ให้ padding และ border ถูกคำนวณเข้าไปในความกว้างและความสูง */
}

.background-order-summary {
    background-image: url('../../../assets/img/pynk/shop/background-how.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-bottom: 120px;
}

.order-summary {
    margin-top: 60px;
    width: 100%;
    max-width: 714px;
    background-color: rgba(255, 255, 255, 0.54);
    border-radius: 16px;
    border: 2px solid var(--white);
    padding: 42px 22px 42px 22px;
    margin-bottom: 64px;

}

.order {
    border: 1px solid var(--white);
}

.text-head-order-summary {
    color: var(--main-text);
    font-size: 24px;
    font-family: SemiBoldPynk;
}

.text-price-order {
    color: var(--main-text);
    font-size: 20px;
    font-family: SemiBoldPynk;
}

.text-order {
    color: var(--main-text);
    font-size: 16px;
    font-family: SemiBoldPynk;
}

.image-product-order {
    width: 100px;
    height: 99px;
}

.box-add-order {
    position: absolute;
    margin-top: -15px;
}

.delete_bin_line-order {
    margin-left: 32px;
    margin-top: 22px;
    width: 21px;
    height: 21px;
    margin-bottom: 6px;
    cursor: pointer;
}

.line-order-bottom {
    border-bottom: 0.78px solid rgba(0, 0, 0, 0.20);
    margin: 32px 0 32px 0;
}

.body-order {
    padding-left: 20px;
    padding-right: 20px;
}

.shop-more-products {
    margin-top: 32px;
    background-color: var(--white);
    padding: 15px 47px 15px 47px !important;
    border: 1px solid var(--normal-pink);
    color: var(--main-text);
    border-radius: 50px;
    font-size: 18px;
    font-family: SemiBoldPynk;

}

.arrow-left-line {
    width: 20px;
    height: 20px;
    margin-right: 8px;
}

.arrow-left-line-form {
    width: 24px;
    height: 24px;
}

.box-proceed-payment {
    margin-top: 60px;
    max-width: 454px;
    width: 100%;
    min-height: 444px;
    background-color: rgba(255, 255, 255, 0.54);
    border-radius: 16px;
    border: 2px solid var(--white);
    padding: 42px 22px 42px 22px;
}

.input-code {
    position: relative;
}

.button-use {
    right: 0;
    position: absolute;
    width: 87px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    border-radius: 50px;
}

.form-code {
    height: 64px !important;
}

.btn-buy-payment {
    background-color: var(--normal-pink);
    border: 1px solid var(--normal-pink);
    width: 100%;
    margin-left: 0px;
    height: 50px;
    border-radius: 50px;
    color: var(--white);
    font-size: 18px;
    font-family: SemiBoldPynk;
    margin-bottom: 16px;
    margin-top: 32px;
}

.btn-continue-payment {
    background-color: var(--disabled-BG);
    border: 1px solid var(--disabled-BG);
    width: 100%;
    margin-left: 0px;
    height: 50px;
    border-radius: 50px;
    color: var(--white);
    font-size: 18px;
    font-family: SemiBoldPynk;
    margin-bottom: 16px;
    margin-top: 32px;
}


.logo-payment-1 {
    width: 32px;
    height: 32px;
    margin-right: 8px;
    margin-bottom: 8px;
}

.logo-payment-4 {
    width: 95px;
    height: 32px;
    margin-right: 8px;
    margin-bottom: 8px;
}

.logo-payment-5 {
    width: 141px;
    height: 32px;
    margin-bottom: 8px;
}

.modal-body-payment {
    height: auto;
}

.modal-content-payment {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--width) solid var(--width);
    border-radius: 16px;
    outline: 0;
    padding: 0px 32px 53px 32px;
}

.modal-content-payment-form {
    position: relative;
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    color: var(--bs-modal-color);
    pointer-events: auto;
    background-color: var(--bs-modal-bg);
    background-clip: padding-box;
    border: var(--width) solid var(--width);
    border-radius: 16px;
    outline: 0;
    padding: 0px 0px 53px 0px;
}

.want-login {
    text-align: center;
    color: var(--main-text);
    font-size: 24px;
    font-family: SemiBoldPynk;
}

.want-to {
    margin-top: -16px;
}

.btn-want-login {
    background-color: var(--white);
    width: 100%;
    padding: 9px 22px 9px 22px !important;
    border: 1px solid var(--normal-pink);
    color: var(--main-text);
    border-radius: 50px;
    font-size: 20px;
    font-family: RegularPynk;
    margin-left: 0 !important;

}

.or-login {
    text-align: center;
    color: var(--disabled-text);
    font-size: 20px;
    font-family: RegularPynk;
}

.box-button-login {
    padding: 0 25px 0 25px;
}

.icon-login-model {
    width: 20px;
    height: 20px;
    margin-right: 6px;
}

.icon-login {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    margin-left: 16px;
}

.text-login-payment {
    color: var(--main-text);
    font-size: 16px;
    font-family: RegularPynk;
}

.login-payment-span {
    color: var(--normal-red);
}

.box-input {
    padding-left: 16px;
    padding-right: 16px;
}

.input-form-login {
    margin-top: -8px;
    margin-bottom: 16px;
    border-radius: 8px;
    border: 1px solid var(--border);

}

.input-form-login {
    display: block;
    /*  width: 100%; */
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

}

.input-form-login:focus {
    color: #292121;
    background-color: #fff;
    border-color: #e25e96 !important;
    outline: 0;
    box-shadow: 0 0 0 0.25rem hsl(335, 69%, 63%, 0.25);

}

.input-password {
    position: relative;
}

.position-relative {
    position: relative;
}

.show-pass {
    position: absolute;
    display: flex;
    right: 0;
    margin-top: -50px;
    margin-right: 16px;
    color: var(--disabled-text);
    font-size: 16px;
    font-family: RegularPynk;
}

.cursor-pointer {
    cursor: pointer;
}

.forgot-password {
    color: #0094FF;
    font-size: 18px;
    font-family: RegularPynk;
    cursor: pointer;
    text-decoration: underline;
}

.or-access {
    margin-top: 16px;
    text-align: center;
    font-size: 16px;
    font-family: RegularPynk;
    color: #4F4F4F;
}

.box-form-payment {
    position: relative;
    margin-top: 68px;
    width: 100%;
    height: auto;
    background-color: rgba(232, 232, 232, 1);
    border: 2px solid var(--white);
    border-radius: 52px;
    margin-left: 5%;
    margin-right: 5%;
    padding: 39px 77px 85px 77px;
    display: flex;
    justify-content: center;
}

.box-circle {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.circle-number {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    background-color: var(--normal-pink);

}

.circle-number-darkest {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    background-color: var(--darkest-pink);

}

.box-row {
    position: absolute;
    display: flex;
    align-items: center;
}

.circle-number-bg {
    position: absolute;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 100px;
    background-color: var(--disabled-BG);
}

.circle-p {
    margin-top: 41px;
    z-index: 0;
    color: #4A4A4A;
    font-size: 16px;
    font-family: RegularPynk;
}

.circle-p-none {
    color: var(--placeholder) !important;

}

.circle-span {
    color: var(--white);
    font-size: 16px;
    font-family: SemiBoldPynk;
}

.circle-span-disabled {
    color: var(--disabled-text) !important;
    font-size: 16px;
    font-family: SemiBoldPynk;
}



.text-center {
    text-align: center;
}

.box-line-placeholder {
    position: relative;
    margin-top: -45px;
    margin-left: -40px;
    margin-right: 5px;
    left: 0;
    right: 0;
    background-color: var(--placeholder);
    width: 100px;
    height: 7px;
    border-radius: 50px;
}

.box-line-pink {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 5px;
    left: 0;
    right: 0;
    background-color: var(--normal-pink);
    width: 50px;
    height: 7px;
    border-radius: 50px;
}

.box-line-pink-max {
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 5px;
    left: 0;
    right: 0;
    background-color: var(--normal-pink);
    width: 100px;
    height: 7px;
    border-radius: 50px;
}

.line-mr {
    margin-left: 5px !important;
}

.mt-col {
    top: 0;
    margin-top: 127px;
}

.hr-line-white {
    top: 0;
    width: 100%;
    height: 2px;
    background-color: #ffffff;
    border: none;
    position: absolute;
    margin-top: 134px;
    margin-bottom: 32px;

}



.form-payment {
    margin-top: 0px;
    border-radius: 4px;
    border: 1px solid var(--border);
    height: 64px;
    background-color: var(--white);
    margin-bottom: 16px;
}


.show-payment {
    position: absolute;
    display: flex;
    right: 0;
    margin-top: -37px;
    margin-right: 16px;
    flex-direction: row;
}

.show-payment img {
    margin-right: 8px;
    width: 24px;
    height: 24px;
}

.show-payment img:last-child {
    margin-right: 0;
}



.form-check-input2 {
    appearance: none;
    width: 20px;
    height: 20px;
    border: 1px solid #4A4A4A;
    border-radius: 50%;
    background-color: white;
    position: relative;
    cursor: pointer;

}

.form-check-input2:checked::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: var(--normal-pink);
}

.form-check {
    margin-top: 18px;
    position: relative !important;
}

.form-check-label {
    position: absolute;
    margin-top: 0;
    margin-left: 10px;
    color: #000000;
    font-size: 18px;
    font-family: RegularPynk;
}

.check-image {
    width: 24px;
    height: 24px;
}

.mt-32 .input-password button {
    margin-top: 16px !important;
}

.order-details {
    width: 100%;
    height: auto;
    border: 1px solid var(--placeholder);
    border-radius: 16px;
    padding: 34px 24px 16px 24px;
}

.mt-32-576 {
    padding-right: 24px;
}

.edit-order {
    color: var(--normal-pink);
    font-size: 18px;
    font-family: RegularPynk;
    text-decoration: underline;
    cursor: pointer;
    margin-left: 24px;
}

.line-hr-order {
    position: relative;
    margin: 32px 0px 32px;
    width: 100%;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.2);
}

.amount-be-paid {
    color: var(--normal-pink);
    font-size: 24px;
    font-family: SemiBoldPynk;
}

.amount-be-paid span {
    font-size: 32px;
}



.customer-first-child-l {
    margin-left: 16px;
}

.customer-first-child-r {
    margin-right: 16px;
}

.customer-mt {
    margin-top: 48px;
}

.error-from {
    margin-top: -10px;
    color: red;
}

.col-12 .col-md-6>div>div>div {
    display: block !important;
}

.condition {
    margin-left: 16px;
}

.condition-span {
    color: #128BFB;
}

.box-promptPay {
    position: relative;
    margin-top: 64px;
    width: 100%;
    height: auto;
    border-radius: 52px;
    margin-left: 5%;
    margin-right: 5%;
    padding: 39px 77px 85px 77px;
    display: flex;
    justify-content: center;
}

.background-promptPay {
    background-color: var(--white);
}

.padding-left-right {
    padding-left: 24px;
    padding-right: 24px;
}

.qrcode-pay-image {
    width: 320px;
    height: 430px;
}

.text-qrcode-qrcode {
    color: var(--main-text);
    font-size: 24px;
    font-family: SemiBoldPynk;
}



.check-success-img {
    margin-top: 12px;
    width: 48px;
    height: 48px;
    margin-bottom: 16px;
}

.order-number {
    margin-top: -8px;
    color: #5A6E7B;
    font-size: 20px;
    font-family: RegularPynk;
    margin-bottom: 32px;
}

.please-contact {
    margin-top: 32px;
    color: #4F4F4F;
    font-size: 16px;
    font-family: SemiBoldPynk;
}

.text-left-order {
    margin-left: 16px;
}

.input-height {
    height: 64px !important;
}

.label-prompt_pay {
    margin-left: 20px !important;
    margin-top: -40px !important;
}

/* เลือกปุ่ม Google Login */

.login-button .google button {
    font-size: 20px !important;
    font-family: RegularPynk !important;
    width: 100%;
    margin-left: 0 !important;
    background: rgb(255, 255, 255);
    color: var(--main-text) !important;
    border: 1px solid var(--normal-pink) !important;
    border-radius: 50px !important;
    box-shadow: none !important;
}

.login-button .google button div {
    margin-right: 10px;
    background: rgb(255, 255, 255);
    margin-left: 70px !important;

    border-radius: 50px !important;
}

.login-button .google button span {
    margin-left: -8px !important;
}

.login-path button {
    margin-right: 10px;
    background: rgb(255, 255, 255);
    padding: 0 !important;
    border-radius: 0px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 1px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px !important;
}



.nav-logout button {
    padding: 0 !important;
    border: none !important;
    border-radius: 0px !important;
    box-shadow: none !important;
}

.nav-logout button span {
    padding: 0 !important;
}

.nav-logout button div {
    margin-left: -16 !important;
    margin-right: 0 !important;
    box-shadow: none !important;
}



/* end */
.tax-invoice .modal-body {
    height: auto;
}