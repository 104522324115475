* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

ul {
    /* list-style: none; */
}

/* li {
    background-color: white;
    padding: 16px;
    border: 1px solid #EF60A3;
    border-radius: 10px;
    font-size: 20px;
    margin-top: 10px;
} */

.background-container {
    background-image: url('../../../assets/img/default_background.jpg');
    background-color: blue;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: 100% 100%;
    height: 85vh;
  }

.frame{
    display: block;
    background-color: white;
    border-radius: 10px;
    padding: 10px 20px;
    width: 50%;
    height: 75%;
    position: relative;
    transform: translate(50%,25%);

}

.questionare-container{
    display: grid;
    justify-content: center;
    text-align: center;
}

.questionare-card{

}

.progress-bar-container {
    width: 100%;
    background-color: #DDDDDD;
    height: 5px;
    border-radius: 10px;
    overflow: hidden;
  }
  
  .progress-bar {
    height: 100%;
    background-color: #EF60A3;
    transition: width 0.3s ease-in-out;
  }

  .lower-progress-bar{
    display: flex;
    justify-content: space-between;
  }
