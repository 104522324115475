.file-upload {
    position: relative;
    display: inline-block;
  }
  
  .file-upload input[type="file"] {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
  
  .file-upload label {
    display: inline-block;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: 1px solid #007bff;
    cursor: pointer;
  }
  
  .file-upload label i {
    margin-right: 8px;
  }
  
  .file-upload label:hover {
    background-color: #0056b3;
  }
  