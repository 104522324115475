@media only screen and (max-width: 576px) {
    .head-shop {
        display: none !important;
    }

    .head-shop-background {
        display: none
    }

    .mt-shop {
        margin-top: 222px !important;
    }

    .gap-5 {
        gap: 1rem !important;
    }

    .slider-div {
        padding-left: 0px !important;
        padding-right: 0px !important;
    }

    .height-box-slider {
        height: 300px !important;
    }

    .bfr-coupon {
        width: 254px !important;
        height: 127px !important;
    }

    .box-bfr_coupon {
        justify-content: normal !important;
        margin-top: 64px !important;
        margin-bottom: 64px !important;
    }
}

@media (min-width: 768px) {
    .d-flex {
        overflow-x: hidden;
    }
}

.product .img_product {
    border: 2px solid #E8E8E8;
    border-bottom: 0;
    transition: all;
}

.product:hover .img_product {
    border: 2px solid #EF60A3;
    border-bottom: 0;
    transition: all;
}

.product .detail_product {
    border: 2px solid #E8E8E8;
    transition: all;
}

.product:hover .detail_product {
    border: 2px solid #EF60A3;
    transition: all;
}

.product button {
    display: none;
    transition: all;
}

.product:hover button {
    display: flex;
    transition: all;
}

.icon-kg {
    position: absolute;
    z-index: 0;
    margin-top: 50px;
}

.icon-kg-text {
    margin-top: 100px;
    text-align: center;
    color: #2C2E2F;
    font-family: RegularPynk;
}

.icon_other-text {
    text-align: center;
    color: #2C2E2F;
    font-family: RegularPynk;
}

.slider-div {
    padding-left: 32px;
    padding-right: 32px;
}

.next-btn2 {
    right: 0px !important;
}

.icon-cart-white {
    position: absolute;
    margin-left: 40px;
}

.span-div {
    margin-top: -40px;
    margin-left: 30px;
}

.height-box-slider {
    margin-top: 70px;
    height: 500px;
}

.bfr-coupon {
    width: 584px;
    height: 292px;
}



.d-flex::-webkit-scrollbar {
    width: 768px;
}

.d-flex::-webkit-scrollbar-thumb {
    display: none;
}

.box-bfr_coupon {
    justify-content: center;
    margin-top: 64px;
    margin-bottom: 195px;
}

.head-shop-background {
    max-height: 355px;
    width: 100%;
    background: linear-gradient(to right, #C5086E, #EF60A3, #F7ABCE, #F7ABCE);

}

.text-title {
    width: 100%;
    height: auto;
    max-width: 375px;
    max-height: 62px;

}


.shop-image-background {

    background-image: url('../../../../src/assets/img/pynk/shop/FrameLink.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    padding-left: 10.9%;
    /* 149px */
    padding-top: 58px;
    padding-bottom: 32px;
}

.number40-column {
    display: flex;
    flex-direction: column;


}

.pro-1 {
    margin-top: -10%;
    width: 100%;
    height: auto;
    max-width: 804px;
    max-height: 475px;
}


.number-40 {
    width: 100%;
    height: auto;
    max-width: 389px;
    max-height: 182px;
}

