@font-face {
  font-family: "Light";
  font-style: normal;
  font-weight: normal;
  src: url(../fonts/Prompt-Light.ttf);
}

@font-face {
  font-family: "BdCondHe";
  src: url(../fonts/Prompt-Bold.ttf);
}


@font-face {
  font-family: "Regular";
  src: url(../fonts/Prompt-Regular.ttf);
}

@font-face {
  font-family: "Medium";
  src: url(../fonts/Prompt-Medium.ttf);
}

@font-face {
  font-family: "SemiBold";
  src: url(../fonts/Prompt-SemiBold.ttf);
}

.navbar-pynk {
  margin-bottom: 145px;
}

.information-nav {
  display: block;
  padding-bottom: 0px;
}

.information-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 16px 0px 16px;
  background-color: #ffffff !important;
  border: 1px solid #ffffff;
  height: 90px;
}

.information-box-row2 {
  /*   display: flex;
    align-items: center; */
  padding: 0;
  background-color: #4a4a4a !important;
  height: 56px;
}

.information-box-row3 {
  display: flex;
  align-items: center;
  padding: 0px 0px 0px 0px;
  background-color: #ffffff;
  height: 73px;
}

.navbar-flex-center {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 577px) {
  .user-line {
    display: none !important;
  }
}

.group-icon {
  margin-top: 20px;
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.link-pynk {
  color: #acacac !important;
  font-size: 16px !important;
  font-weight: 500;
  cursor: pointer;
  /* font-family: SemiBoldPynk !important; */
}

.active2 {
  border: none;
  border-radius: 0;
}

.flex-container {
  display: flex;
  align-items: center;
  /* แนวตั้งตรงกลาง */
  gap: 10px;
  /* ระยะห่างระหว่างองค์ประกอบ */
}

.truck-line-icon {
  width: 20px;
  height: 20px;
}

.nav-linkHead2 {
  color: #fff;
  /* font-family: SemiBoldPynk !important; */
  font-size: 16px !important;
  background-color: #ef60a3;
  border: none;
  border-radius: 50px;
  padding: 11px 16px;
  gap: 8px;
  width: 200px;
  height: 50px;
}

.icon-edit {
  width: 25px !important;
  padding-right: 5px;
  cursor: pointer !important;
}

.google_style {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 32px;
}

.google_style:hover {
  height: 32px;
  background-color: var(--bs-dropdown-link-hover-bg);
  color: #e25e96;
}

.navbarNav-ul {
  padding: 12px !important;
  background-color: #4a4a4a;
}

.navbarNav-ul-collpase {
  padding: 12px !important;
  background-color: #fff8fb;
}

.nav-ellipse24 {
  width: 24px;
  height: auto;
  margin-right: 8px;
}

.cook-email {
  font-size: 12px !important;
  font-family: SemiBold !important;
}

.color_link {
  cursor: pointer;
  /* background-color: #e9ecef; */
}
.color_link:hover {
  /* background-color: #e9ecef; */
  color: #e25e96 !important;
}

.dropdown-item:active {
  background-color: transparent !important;
  color: inherit !important;
}
